import React from 'react'

import MyHeader from '../components/MyHeader'
import picStudio2 from '../images/william_film_studio/2.jpg'

const AboutPage = () => {

  return (
    <div>
      <MyHeader/>
      <div className="section flex-wide">
        <div className="flex-tall">
          <img className="img-medium" src={picStudio2} alt="Will sitting with Guitar" />
        </div>
        <div className="flex-text text-medium-size">
          <p>
            Will Worden is a singer and performer, developed by roots, country-blues, gospel and tradition folk influences. Influenced by early Elvis Presley, Charlie Rich, Marty Robbins, Merle Haggard, Glen Campbell, etc. He grew up from the green grass of Sugar Land, Texas and left university to head west to California; now residing in the canyons of the Westside.
          </p><p>
            He is currently working on his debut solo record, as well as a 45 release with Nick Waterhouse.
          </p><p>
            Apart from being a heavy contributor to the busy Los Angeles live music, you can enjoy his studio work as a session guitar player, recording with Sylvie, Lana Del Rey, Nick Waterhouse, and more which release next year.
          </p><p>
            ‘2023-2024 promises to be the worldwide debut of a new voice in American music’ 
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutPage