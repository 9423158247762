import React from 'react'

import MyHeader from '../components/MyHeader'
import picStudio2 from '../images/william_film_studio/2.jpg'

const HomePage = () => {

  return (
    <div>
      <MyHeader />
      <img className='img-large' src={picStudio2} alt="Will Worden" />
    </div>
  )

}

export default HomePage