import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import TourPage from './pages/TourPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const App = () => {
  return <div className="site-wrapper">
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route exact path="/tour/" element={<TourPage/>}/>
        <Route exact path="/about/" element={<AboutPage/>}/>
        <Route exact path="/contact/" element={<ContactPage/>}/>
      </Routes>
    </Router>
  </div>
}

export default App;