import React from 'react'

import MyHeader from '../components/MyHeader'
import picStudio16 from '../images/william_film_studio/16.jpg'

const TourPage = () => {

  let inc = 0;
  let dates = [
    { key: inc++, label: "1 September - Ojai, CA - Ojai Deer Lodge w / Glenn Annie", link: "" },
    { key: inc++, label: "7 September - San Luis Obispo, CA w / Glenn Annie", link: "" },
    { key: inc++, label: "23 September - Big Sur, CA - Rainbow Festival w / Babe Rainbow", link: "" },
    { key: inc++, label: "24 September - San Pedro, CA - Rainbow Festival w / Babe Rainbow", link: "https://www.ticketweb.com/event/rainbow-rock-ft-babe-rainbow-brouwerij-west-tickets/13490608?fbclid=PAAaYYCL-XQ_9XzbOGW9YniNpfGKYgSBDlAHozu1Ag0Ud3C4RUb7Hl7k97yg4_aem_AbWuU-3GjJ7IXraosGOhCiCem9lhKfJ8AdKWWOQm7arYwfDq5S9gkujhvf-1WeUOlFI" },
    { key: inc++, label: "4 November - Scottsdale, AZ - Dreamy Draw Festival", link: "" },
    { key: inc++, label: "6 November - Berlin, DE - Huxleys", link: "https://www.eventim.de/noapp/event/16919135/" },
    { key: inc++, label: "7 November - Paris, FR - La Trabendo", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/552084/idtier/18864121" },
    { key: inc++, label: "9 November - Rennes, FR - L'Etage", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/551584/idtier/18864121" },
    { key: inc++, label: "10 November - Saint-Lo, FR - Theater(Les RDV Soniques)", link: "https://billetterie.lenormandy.net/" }, //(individual show going up in Sept / �coming soon�)
    { key: inc++, label: "11 November - La Roche-sur-Yon, FR - Quai M", link: "https://quai-m.fr/agenda/nick-waterhouse" },
    { key: inc++, label: "12 November - La Rochelle, FR - La Sirene", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-1ere-partie-billet/idmanif/551734/idtier/18864121" },
    { key: inc++, label: "14 November - Angers, FR - Le Chabada", link: "https://billetterie.lechabada.com/evenement/14-11-2023-20-30-nick-waterhouse" },
    { key: inc++, label: "15 November - Le Havre, FR - Le Tetris", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/553133/idtier/18864121" },
    { key: inc++, label: "16 November - Beauvais, FR - L'Ouvre - Boite", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/561008/idtier/18864121" },
    // in Jill's email but not Will's doc. will said leave out for now { key: inc++, label: "17 November - Orleans, FR", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/552024/idtier/18864121" },
    { key: inc++, label: "18 November - Meisenthal, FR - Halle Verriere", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/552410/idtier/18864121" },
    { key: inc++, label: "19 November - Marseille, FR - Espace Julien", link: "https://www.ticketmaster.fr/fr/manifestation/nick-waterhouse-billet/idmanif/551536/idtier/18864121" },
    { key: inc++, label: "21 November - Bordeaux, FR - Rock School Barbey", link: "https://www.seetickets.com/fr/d/event/nick-waterhouse/rock-school-barbey/9743321" },
    { key: inc++, label: "22 November - Bilbao, ES - Albia Kafe Antzokia SA", link: "http://sarrerak.kafeantzokia.eus/web/?menu=138&pagina=entradas&item=44472&siteID=kafeantzokia" },
    { key: inc++, label: "23 November - Santiago de Compostela, ES - Capitol", link: "https://houstonpartymusic.com/tour-dates/782/" }, //(individual show going up in Sept / �coming soon�) 
    { key: inc++, label: "24 November - Madrid, ES - Sala Cool", link: "https://www.ticketmaster.es/event/nick-waterhouse-entradas/35523" },
    { key: inc++, label: "25 November - Valencia, ES - 16 Toneladas", link: "https://movingtickets.com/Entradas-Nick-Waterhouse-concierto-en-16-Toneladas-Valencia/" },
    { key: inc++, label: "26 November - Barcelona, ES - La Nau Locales de Ensayo ", link: "https://www.ticketmaster.es/event/nick-waterhouse-entradas/35525" },
    { key: inc++, label: "28 November - Antwerp, BE - De Roma", link: "https://www.fnacspectacles.com/event/nick-waterhouse-de-roma-borgerhout-16952692/" },
    { key: inc++, label: "29 November - Utrecht, NL - TivoliVredenburg(Pandora Room)", link: "https://www.ticketmaster.nl/event/nick-waterhouse-tickets/292359" },
    { key: inc++, label: "30 November - Haarlem, NL - Patronaat", link: "https://www.ticketmaster.nl/event/nick-waterhouse--will-worden-tickets/292287" },
    { key: inc++, label: "2 December - Dublin, IE - The Grand Social", link: "https://www.ticketmaster.ie/nick-waterhouse-dublin-02-12-2023/event/18005E868A2B2297" },
    { key: inc++, label: "3 December - London, UK - Scala", link: "https://www.ticketweb.uk/event/nick-waterhouse-scala-tickets/13117935" },
  ]

  const listItems = dates.map(tourItem => {
    let ticketButton = <div><a className="ticket-button" href={tourItem.link} target="_blank" rel="noopener noreferrer">TICKETS</a></div>;
    return <React.Fragment key={tourItem.key}>
      <div className="tour-item" style={{ marginLeft: "2rem", marginRight: "2rem", textAlign: "center" }}>{tourItem.label}</div>
      {!!tourItem.link ? ticketButton : null}
      <hr style={{ width: "20%", border: "1px solid rgba(128, 128, 128, 0.8)" }} />
    </React.Fragment>
  });

  return (
    <div>
      <MyHeader />
      <div className="parallax tour-parallax-container">
        <div className="parallax__layer parallax__layer--back tour-background-container">
          <img id="tour-background" src={picStudio16} />
        </div>
        <div id="tour-items-container" className="parallax__layer parallax__layer--base">
          {listItems}
        </div>
      </div>
    </div>
  )

}

export default TourPage