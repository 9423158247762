import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; //navigate instead of history = useHistory in newer versions of react-router-dom
import "./MyHeader.css";
import $ from 'jquery';
import picLogo from "../images/ui-images/logo_willandhaylie.png";
import picHome from '../images/ui-images/home.png';
import picAbout from '../images/ui-images/about.png';
import picContact from '../images/ui-images/contact.png';

const MyHeader = (props) => {
  //define navigation functions
  let navigate = useNavigate();
  const sendToHome = (e) => {
    $('#burger-menu').hide();
    navigate("/");
  }
  const sendToAbout = (e) => {
    $('#burger-menu').hide();
    navigate("/about/");
  }
  const sendToTour = (e) => {
    $('#burger-menu').hide();
    navigate("/tour/");
  }
  const sendToContact = (e) => {
    $('#burger-menu').hide();
    navigate("/contact/");
  }

  //adds .on class to the nav button corresponding to the active page
  //this is to underline the current page in the navbar
  $(function(){
    var $page = window.location.pathname; // this will be "/", "/about/" or "/contact/"
    $page += "tag"; // this will then be "/tag", "/about/tag" or "/contact/tag"
    //set the IDs of each button to match these "tag" strings
    $('.nav-button').each(function(){
        //get ID of every .nav-button (which should match a "tag" string)
        var $id = $(this).attr('id');
        if ($id === $page) { //if current page tag string matches the ID, add .on class
          $(this).addClass('on');
        } else {
          $(this).removeClass('on');
        }
    });
  });
  // when window width is below 760, hide #header-nav and show #header-burger
  // #header-burger will be open the nav menu when clicked on
  
  useEffect(() => {
    toggleBurger();
    burgerActive = true;
    $('#burger-menu').hide();
  }, [])
  
  $(window).on("resize", toggleBurger);

  function toggleBurger(){
    if ($(window).width() < 760) {
      $('#header-nav').hide();
      $('#header-burger').show();
    } else {
      $('#header-nav').show();
      $('#header-burger').hide();
    }
  };
  
  let burgerActive = false;
  const toggleBurgerNavMenu = () => {
    if (burgerActive) {
      $('#burger-menu').show(500);
      // console.log("show burger menu")
    } else {
      $('#burger-menu').hide(500);
      // console.log("hide burger menu")
    }
    burgerActive = !burgerActive;
  }
  
  return (
    <div className='header'>
      WILL WORDEN
      <div id="header-nav">
        <a className="nav-button" id="/tag" onClick={sendToHome}>HOME</a>
        <a className="nav-button" id="/tour/tag" onClick={sendToTour}>TOUR</a>
        <a className="nav-button" id="/about/tag" onClick={sendToAbout}>ABOUT</a>
        <a className="nav-button" id="/contact/tag" onClick={sendToContact}>CONTACT</a>
        <a className="nav-button nav-button-icon" href='https://www.instagram.com/godspeedwarrior/?hl=en' target="_blank"><svg style={{width: "1.2rem"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
      </div>
      <div id="header-burger" onClick={toggleBurgerNavMenu} hidden>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
        <div id="burger-menu" hidden>
          <div id="burger-menu-flex">
            <a className="nav-button" id="/tag" onClick={sendToHome}>HOME</a>
            <a className="nav-button" id="/tour/tag" onClick={sendToTour}>TOUR</a>
            <a className="nav-button" id="/about/tag" onClick={sendToAbout}>ABOUT</a>
            <a className="nav-button" id="/contact/tag" onClick={sendToContact}>CONTACT</a>
            <a className="nav-button nav-button-icon" href='https://www.instagram.com/godspeedwarrior/?hl=en' target="_blank"><svg style={{ width: "3.5vh" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyHeader