import React from 'react';

import MyHeader from '../components/MyHeader';

const ContactPage = () => {

  return (
    <div>
      <MyHeader />
      <div id="contact-body">
        <p>CONTACT</p>
        <p>Management: <a href="mailto:brian@nomadsound.com">brian@nomadsound.com</a></p>
      </div>
    </div>
  )
}

export default ContactPage